<footer>
    <div style="background-color: #245a76;color: #fff;">
        <div class="container-fluid footer-row1">
            <div class="row">
                <div class="col-md-8 footer-items">
                    <p class="pt-4 footer-text">
                        We provide research-based advisory services to assist developing countries deliver on
                        the Paris Agreement and
                        Sustainable Development Goals
                    </p>
                </div>
                <div class="col-md-3  bg-img">
                    <!-- <img src="assets/images/earth_dot.png" style="max-width: 100%;height: auto;"> -->
                </div>
            </div>
        </div>
    </div>
    <div class="footer-row2-section">
        <div class="container-fluid">
            <div class="footer-row2 row ">
                <div col-md-7>
                    <p class="footer-text-two">
                        We work to implement UN Environment’s Climate Change Strategy and Energy
                        Programme
                    </p>
                </div>
                <div col-md-2>
                    <img src="assets/images/image.png" style="width: 100%;height: auto;">
                </div>
            </div>
        </div>
    </div>
</footer>